import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="collapsible"
export default class extends Controller {
  connect() {
    const options = {
      onOpenEnd: function() {
        const tabs = document.querySelectorAll('ul.tabs');
        M.Tabs.init(tabs);
      }
    };
    M.Collapsible.init(this.element, options);
  }
}
